
import React from "react";

import BBB from "../../images/BBB-1.svg";
import QB from "../../images/QB.svg";
import DEXT from "../../images/Dext-2.svg";
import QB2 from "../../images/qb2.svg";
import { StaticImage } from "gatsby-plugin-image";
import Plooto from "../../images/plooto.svg";
import Link from "gatsby-link";
export default function Example() {
  return (
    <main>
      <div>
        {/* Hero card */}
        <div className="relative">
          <div className="absolute inset-x-0 bottom-0 h-1/2 bg-white" />
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
              <div
                className="absolute inset-0 shadow-xl overflow-hidden sm:overflow-hidden aspect-w-5 md:aspect-h-0 "
                style={{
                  borderRadius: "5px",
                  webkitBorderRadius: "8px",
                  mozBorderRadius: "5px",
                  khtmlBorderRadius: "5px",
                  overflow: "hidden",
                  border: "3px transparent",
                  webkitMaskImage: "webkit-radial-gradient(white, black)",
                }}
              >
                <StaticImage
                  src="../../images/subscribing.jpg"
                  alt="A dinosaur"
                  placeholder="fixed"
                  layout="fullWidth"
                  imgStyle={{ overflow: "hidden" }}
                  imgClassName="rounded-lg object-fit"
                  style={{ overflow: "hidden" }}
                  border="5px"
                  // You can optionally force an aspect ratio for the generated image
                  aspectRatio={1 / 1}
                  // This is a presentational image, so the alt should be an empty string
                
                  // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
                  formats={["auto", "webp", "avif"]}
                />
                <div
                  className="absolute inset-0 bg-gray-500 mix-blend-multiply sm:flex-shrink-0"
                  style={{
                    borderRadius: "5px",
                    webkitBorderRadius: "8px",
                    mozBorderRadius: "5px",
                    khtmlBorderRadius: "5px",
                    overflow: "hidden",
                  }}
                />
              </div>
              <div className="relative px-4 py-12 sm:px-6 sm:py-24  lg:py-32 lg:px-8 xs:pt-12">
                <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                  <span className="block text-white">Certus Bookkeeping</span>
                  <span className="block text-title">Newsletter</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      {/* More main page content here... */}
    </main>
  );
}
