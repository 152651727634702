import React from "react";
import { Helmet } from 'react-helmet'
export default function Example() {
  return (
    <Helmet htmlAttributes={{
      lang: 'en',
    }}>
      <title>Thank You For Subscribing | Certus Bookkeeping</title>
      <meta name="description" content="Thank you for subscribing to our newsletter." />
      <link rel="canonical" href="https://certusbookkeeping.com/thank-you-subscription" />
    </Helmet>
  );
}
